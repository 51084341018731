import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/components/v2/Toast/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.3_next@15.0.4_@babel+core@7.26.0_@playwright+test@1.50.0_react-dom@1_cpmpa2dczmamvyshku4uciv2nm/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.3_next@15.0.4_@babel+core@7.26.0_@playwright+test@1.50.0_react-dom@1_cpmpa2dczmamvyshku4uciv2nm/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.3_next@15.0.4_@babel+core@7.26.0_@playwright+test@1.50.0_react-dom@1_cpmpa2dczmamvyshku4uciv2nm/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.4_@babel+core@7.26.0_@playwright+test@1.50.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.4_@babel+core@7.26.0_@playwright+test@1.50.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/scalis-components/core/notification/notification.tsx");
;
import(/* webpackMode: "eager" */ "/app/scalis-components/core/notification/useNotification.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/scalis-components/datadog-init.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/scalis-components/session-timeout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/styles/globals.css");
