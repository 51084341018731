import { Calendar } from '~/bff/graphql/generated/graphql'
import { EventType } from './context.types'

type EventCondition = (event: EventType) => boolean

export const isExternalEventVisible = (showExternalEvents: boolean): EventCondition => {
  return (event) => showExternalEvents || showExternalEvents === !event.originalEvent.isScalisEvent
}

export const isJobListingVisible = (selectedJobListings: number[]): EventCondition => {
  return (event) => {
    const regardingJobs = event.originalEvent.regardingJobs
    if (regardingJobs?.length > 0) {
      return regardingJobs?.some((jobId: number) => selectedJobListings.includes(jobId))
    }
    return true
  }
}

export const adjustColorBrightness = (jobId?: number | number[]) => {
  // Base RGB values for a lighter blue color
  const baseR = 179
  const baseG = 198
  const baseB = 255

  // Create variations based on jobId
  const id = Array.isArray(jobId) ? jobId[0] : jobId
  const variation = (id || 0) % 3

  let newR = baseR,
    newG = baseG,
    newB = baseB
  switch (variation) {
    case 0: // Light blue (default)
      break
    case 1: // Light purple
      newR = Math.min(255, baseR + 20)
      newG = Math.max(180, baseG - 18)
      newB = Math.min(255, baseB - 10)
      break
    case 2: // Light green
      newR = Math.max(180, baseR - 30)
      newG = Math.min(255, baseG + 10)
      newB = Math.max(180, baseB - 75)
      break
    // Add more cases as needed
  }

  // Ensure minimum brightness for text contrast
  const minBrightness = 180
  newR = Math.max(minBrightness, newR)
  newG = Math.max(minBrightness, newG)
  newB = Math.max(minBrightness, newB)

  return `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`
}

export const removeExternalEvents = (calendars?: Calendar[]) => {
  return calendars?.map((calendar) => {
    return {
      ...calendar,
      CalendarEvents: calendar.CalendarEvents?.filter((event) => event.isScalisEvent),
    }
  })
}
